(function () {
  var bodyElement = document.querySelector("body");
  var loaderElement = document.querySelector("#initial-loader");

  document.addEventListener("readystatechange", function () {
    if (document.readyState === "complete") {
      setTimeout(function () {
        bodyElement.removeChild(loaderElement);
      }, 200);
    }
  });
})();
